var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-snackbar',{attrs:{"color":_vm.snackbarColor,"right":_vm.x === 'right',"timeout":_vm.timeout,"top":_vm.y === 'top'},model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(_vm._s(_vm.snackbarMessage))]),_c('v-row',[_c('loading',{attrs:{"active":_vm.isLoading,"loader":_vm.loader}})],1),_c('v-row',[_c('v-col',{attrs:{"xs":"12"}},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.items,"options":_vm.options,"server-items-length":_vm.totalItems,"footer-props":{
                  'items-per-page-options': [10, 20, 30]
                },"loading":_vm.loading},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.indexNo",fn:function(ref){
                var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.items.indexOf(item) + 1))])]}},{key:"item.didStatus",fn:function(ref){
                var item = ref.item;
return [(item.didStatus == 'DID_ACTIVATED')?_c('span',[_vm._v("ACTIVATED")]):_vm._e(),(item.didStatus == 'DID_INACTIVE')?_c('span',[_vm._v("INACTIVE")]):_vm._e(),(item.didStatus == 'DID_PENDING')?_c('span',[_vm._v("PENDING")]):_vm._e(),(item.didStatus == 'DID_SUSPENDED')?_c('span',[_vm._v("SUSPENDED")]):_vm._e(),(item.didStatus == 'DID_TERMINATED')?_c('span',[_vm._v("TERMINATED")]):_vm._e()]}},{key:"item.firstName",fn:function(ref){
                var item = ref.item;
return [_vm._v(" "+_vm._s(item.firstName)+" "+_vm._s(item.lastName)+" ")]}},{key:"item.documentInfoId",fn:function(ref){
                var item = ref.item;
return [_c('a',{attrs:{"href":"javascript:void(0)"},on:{"click":function($event){return _vm.getDocumentData(item)}}},[_vm._v(_vm._s(item.documentInfoId))])]}},{key:"item.creationDate",fn:function(ref){
                var item = ref.item;
return [_vm._v(" "+_vm._s((item.creationDate).slice(0,10))+" ")]}},{key:"item.lastRenewalDate",fn:function(ref){
                var item = ref.item;
return [_vm._v(" "+_vm._s(new Date(item.lastRenewalDate).toLocaleDateString())+" ")]}},{key:"item.nextRenewalDate",fn:function(ref){
                var item = ref.item;
return [_vm._v(" "+_vm._s(new Date(item.nextRenewalDate).toLocaleDateString())+" ")]}},{key:"item.viewOrderDetail",fn:function(ref){
                var item = ref.item;
return [_c('router-link',{staticStyle:{"text-decoration":"none !important"},attrs:{"to":{
                      name: 'Order Detail',
                      query: { orderId: item.orderId, partyId: item.partyId },
                    }}},[_c('v-btn',{staticClass:"mr-2",attrs:{"x-small":"","outlined":"","fab":"","color":"green","size":"25"}},[_c('v-icon',[_vm._v("visibility")])],1)],1)]}},{key:"item.viewAction",fn:function(ref){
                    var item = ref.item;
return [_c('v-icon',{attrs:{"color":"info"},on:{"click":function($event){return _vm.viewOrder(item)}}},[_vm._v("visibility")])]}}],null,true)})],1)],1),_c('v-dialog',{attrs:{"max-width":"90%"},model:{value:(_vm.viewUserDocumentList),callback:function ($$v) {_vm.viewUserDocumentList=$$v},expression:"viewUserDocumentList"}},[_c('uploadDocumentsVue',{attrs:{"userDocumentInfo":_vm.userDocumentInfo},on:{"close-user-modal":_vm.closeUserModal}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }